import React, {useEffect, useState} from "react";

export const FiltersContext = React.createContext('');

export const FiltersProvider = ({value, children}) => {

    const [loading, setLoading] = useState(true);
    const [filtroSoci, setFiltroSoci] = useState({});
    const [filtroVeicoli, setFiltroVeicoli] = useState({});
    const [filtroCertificati, setFiltroCertificati] = useState({});
    const [filtroMovimenti, setFiltroMovimenti] = useState({});

    const routesToKeepSoci = ['/list-persone', '/upd-socio', '/info-socio'];
    const routesToKeepVeicoli = ['/list-veicoli', '/upd-veicolo', '/info-veicolo'];
    const routesToKeepCertificati = ['/list-certificati', '/upd-certificato', '/info-certificato', '/doc-aggiuntivi-cert', '/quota-cert', '/dich-resp-crs'];
    const routesToKeepMovimenti = ['/list-movimenti', '/upd-movimento', '/stampa-movimenti'];

    function initFiltroSoci() {
        setFiltroSoci({
            nome: '',
            cognome: '',
            filtroTessera: '',
            codiceFiscale: '',
            azienda: '',
            indirizzo: '',
            cap: '',
            localita: '',
            note: '',
            provincia: '',
            regione: '',
            nazione: '',
            tipoSocio: '',
            annoIscrizione: '',
            scadenza: '',
            minVeicoli: '',
            daRatificare: '',
            newsletter: '',
            delegazione: '',
            tesseraAsi: '',
            club: '',
            id_agenzia: '',
            registro: '',
            ordine: '',
            email: '',
            tipoStato: ''
        });
    }

    function initFiltroVeicoli() {
        setFiltroVeicoli({
            nome: '',
            cognome: '',
            filtroVeicolo: '',
            marca: '',
            tipo: '',
            filtroTessera: '',
            codiceFiscale: '',
            azienda: '',
            indirizzo: '',
            cap: '',
            localita: '',
            notePersona: '',
            noteVeicolo: '',
            provincia: '',
            regione: '',
            nazione: '',
            tipoSocio: '',
            annoIscrizione: '',
            scadenza: '',
            anno: '',
            daRatificare: '',
            newsletter: '',
            delegazione: '',
            tesseraAsi: '',
            club: '',
            targa: '',
            carrozzeria: '',
            marcaCarrozzeria: '',
            colore: '',
            tipoMotore: '',
            numeroMotore: '',
            numeroTelaio: '',
            registroVeicolo: '',
            numeroRegistrazione: '',
            bollo: '',
            omologato: '',
            data_registrazione: null,
            registro: '',
            ordine: '',
            data_da: '',
            data_a: ''
        });
    }

    function initFiltroCertificati() {
        setFiltroCertificati({
            targa: '',
            nominativo: '',
            marca: '',
            tipo: '',
            id_tipo_certificato: '',
            data_registrazione_da: '',
            data_registrazione_a: '',
        });
    }

    function initFiltroMovimenti() {
        setFiltroMovimenti({
            anno: '',
            mese: '',
            nominativo: '',
            rif_fattura: '',
            note: '',
            id_tipo_movimento: '',
            id_conto: '',
            id_causale_movimento: '',
            importo: '',
            data_da: '',
            data_a: '',
        });
    }

    useEffect(() => {
        if (loading === true) {
            initFiltroSoci();
            initFiltroVeicoli();
            initFiltroCertificati();
            initFiltroMovimenti();
            setLoading(false)
        }
    }, []);


    function updateFiltroSoci(campo, valore) {
        setFiltroSoci(prevState => ({
            ...prevState,
            [campo]: valore
        }));
    }

    function updateFiltroVeicoli(campo, valore) {
        setFiltroVeicoli(prevState => ({
            ...prevState,
            [campo]: valore
        }));
    }

    function updateFiltroCertificati(campo, valore) {
        setFiltroCertificati(prevState => ({
            ...prevState,
            [campo]: valore
        }));
    }

    function updateFiltroMovimenti(campo, valore) {
        setFiltroMovimenti(prevState => ({
            ...prevState,
            [campo]: valore
        }));
    }

    return (
        <FiltersContext.Provider
            value={{
                filtroSoci: filtroSoci,
                filtroVeicoli: filtroVeicoli,
                filtroCertificati: filtroCertificati,
                filtroMovimenti: filtroMovimenti,
                initFiltroSoci: initFiltroSoci,
                initFiltroVeicoli: initFiltroVeicoli,
                initFiltroCertificati: initFiltroCertificati,
                initFiltroMovimenti: initFiltroMovimenti,
                updateFiltroSoci: updateFiltroSoci,
                updateFiltroVeicoli: updateFiltroVeicoli,
                updateFiltroCertificati: updateFiltroCertificati,
                updateFiltroMovimenti: updateFiltroMovimenti,
                routesToKeepSoci: routesToKeepSoci,
                routesToKeepVeicoli: routesToKeepVeicoli,
                routesToKeepCertificati: routesToKeepCertificati,
                routesToKeepMovimenti: routesToKeepMovimenti
            }}>{children}</FiltersContext.Provider>
    );
};
